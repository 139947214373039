import React from "react";
import ContentEditable from "react-contenteditable";
import "../../../../components/themes/feedmeonline.css";

const Footer1 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const {
    backgroundColor,
    socialLink1,
    socialLink2,
    socialLink3,
    socialLink4,
    text1,
    text2,
    blockKey,
  } = props;

  return (
    <table
      key={blockKey}
      border="0"
      cellPadding="0"
      cellSpacing="0"
      width="100%"
      role="presentation"
    >
      <tbody>
        <tr>
          <td
            bgcolor={backgroundColor}
            style={{ padding: "20px", backgroundColor: backgroundColor }}
            valign="top"
          >
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              width="100%"
              role="presentation"
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                    valign="top"
                  >
                    <a
                      href={socialLink1}
                      className="footer1ImageLink"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img
                        src={`https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/social/facebook-large-white.png?x-timestamp=${Date.now()}`}
                        width="20"
                        height="20"
                        alt=""
                        className="footer1Image"
                        crossOrigin="anonymous"
                      />
                    </a>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <a
                      href={socialLink2}
                      className="footer1ImageLink"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img
                        src={`https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/social/instagram-large-white.png?x-timestamp=${Date.now()}`}
                        width="21"
                        height="18"
                        alt=""
                        className="footer1Image"
                        crossOrigin="anonymous"
                      />
                    </a>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <a
                      href={socialLink3}
                      className="footer1ImageLink"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img
                        src={`https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/social/pinterest-large-white.png?x-timestamp=${Date.now()}`}
                        width="21"
                        height="20"
                        alt=""
                        className="footer1Image"
                        crossOrigin="anonymous"
                      />
                    </a>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <a
                      href={socialLink4}
                      className="footer1ImageLink"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img
                        src={`https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/social/twitter-large-white.png?x-timestamp=${Date.now()}`}
                        width="20"
                        height="20"
                        alt=""
                        className="footer1Image"
                        crossOrigin="anonymous"
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td height="15" className="footer1Spacer">
                    &nbsp;
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td
                    className="cs-fb-font"
                    style={{
                      color: "#ffffff",
                      fontFamily: brandGuidelines.fontStack.primary,
                      fontSize: brandGuidelines.p.size,
                      lineHeight: brandGuidelines.p.lineHeight,
                      fontWeight: brandGuidelines.p.fontWeight,
                      textAlign: "center",
                    }}
                    valign="top"
                  >
                    <ContentEditable
                      html={text1}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("text1", event);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td height="13" className="footer1Spacer">
                    &nbsp;
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td
                    style={{
                      color: "#ffffff",
                      fontFamily: brandGuidelines.fontStack.primary,
                      fontSize: brandGuidelines.p.size,
                      lineHeight: brandGuidelines.p.lineHeight,
                      fontWeight: brandGuidelines.p.fontWeight,
                      textAlign: "center",
                    }}
                    valign="top"
                  >
                    <unsubscribe
                      style={{
                        color: "#ffffff",
                        fontFamily: brandGuidelines.fontStack.primary,
                        fontSize: brandGuidelines.p.size,
                        lineHeight: brandGuidelines.p.lineHeight,
                        fontWeight: brandGuidelines.p.fontWeight,
                        textDecoration: "none",
                      }}
                    >
                      <ContentEditable
                        html={text2}
                        disabled={false}
                        onChange={(event) => {
                          handleChangeFunction("text2", event);
                        }}
                      />
                    </unsubscribe>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Footer1;
